import './App.css';
import React from "react";
import Storage from '@aws-amplify/storage';
import ImportDisclaimer from './ImportDisclaimer';
import env from "./env.json";
import axios from 'axios';

const headers = {
    'X-SDC-APPLICATION-ID': env.APPID
}

//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: 'us-west-2',
        identityPoolId: env.IDENTITY_POOL_ID
    });
}

export default class UploadForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            campaign: [],
            sfcid: props.sfcid,
            netid: props.netid.netid,
            status: "Waiting",
            fileName: "Choose File"
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.fileUpload = React.createRef();
        // this.fileChange = this.fileChange.bind(this);
    }


    handleClick(event) {
        event.preventDefault();
        event.target.value = null;
        
        let file = this.fileUpload.current.files[0];
        let newFileName = this.props.sfcid + "-" + this.props.netid + ".csv";
        
        var options = {
            acl: 'bucket-owner-full-control',
            contentType: file.type + "; charset=utf-8"

        }
        // SetS3Config("clm-dev-upload", "public")
        // SetS3Config(env.BUCKET, "public")        
        SetS3Config(env.BUCKET, "clm")        

        Storage.put(newFileName, file, options)
            .then(result => {
                this.upload = null;
                this.setState({ status: "Your file upload has started. Check your email for progress status.  If there are no errors, you will get an additional email when the upload is complete." });
            })
            .catch(err => {
                this.setState({ status: `Cannot upload file: ${err}` });
            });
    }
    
    handleFileChange(event) {
        let file = this.fileUpload.current.files[0];
        this.setState({ fileName: file.name, status: "File Selected" });
        console.log("changed file");

    }

    componentDidMount() {
        let baseUrl = env.SSDC_CAMPAIGN_LIST_URL
        let url = baseUrl + this.props.sfcid
        
        // TODO: Delte this commented line when ready/tested
        // axios.get(`https://ssdc-dev.test.trellis.arizona.edu:18655/rel1/clm/fetch/?sdcApplicationId=trellis&sfcid=${this.props.sfcid}`, {
        axios.get(url, {
            headers: headers
        })
            .then(res => {            
                const campaign = res.data.data;
                this.setState({ campaign });
            })
    }

    render() {

        return (

            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <h1 className="mt-0 mb-2">Contact Import</h1>

                            <div id="root">

                            <div className="App mb-6">
                                <div className="form">
                                    <form className="upload-steps" onSubmit={this.handleClick}>

                                        <h2 className="h5 mt-0 mb-3 text-dark-silver">Campaign: {this.state.campaign.map((camp, index) => (
                                            <span className="text-black">{camp.Name}</span>
                                            
                                        ))}</h2>
                                        
                                        <ImportDisclaimer />

                                        <div className="custom-file mt-5 mb-1" style={{ maxWidth: 500 }}>
                                            <input type='file' className="custom-file-input" id="contact-list" accept=".csv" ref={this.fileUpload} onChange={this.handleFileChange} />
                                            <label className="custom-file-label" form="customFile">{this.state.fileName}</label>

                                        </div>
                                        <br/> 
                                        <span className="mt-2 mb-4 badge badge-pill badge-light">
                                            {this.state.status}
                                        </span>
                                        <br /> 
                                        <p className="mt-2 mb-6"><small>Accepted file formats: .csv</small></p>
                                        
                                        <button disabled={this.state.status === "Your file upload has started. Check your email for progress status.  If there are no errors, you will get an additional email when the upload is complete."} className="btn btn-primary" type="submit">Upload</button>
                                            </form>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                );
    }

}

