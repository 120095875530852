import React from "react";

function ImportDisclaimer() {

    return (
        <>
            <p style={{maxWidth: "500px"}}>
                The time it takes for your list to fully import depends on the number of contacts in your .csv and how many other people are using this tool for uploads at the same time.
            </p>
            <table class="table" style={{maxWidth: "500px"}}>
                <thead>
                    <tr>
                        <th scope="col"># of Contacts</th>
                        <th scope="col">Estimated Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>50</td>
                        <td>5 - 10 mins</td>
                    </tr>
                    <tr>
                        <td>200</td>
                        <td>10 - 30 mins</td>
                    </tr>
                    <tr>
                        <td>600</td>
                        <td>30 mins - 1 hour</td>
                    </tr>
                    <tr>
                        <td>1000+</td>
                        <td>1 hour - 8 hours</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default ImportDisclaimer;